import ElMessage from 'element/ElMessage'
import { Api } from 'lib/api/instance'
import parseSlug from 'lib/helpers/parseSlug'
import { isNil, omit } from 'lodash'

import Group from '@/models/Group'

export default {
  namespaced: true,
  state: {
    group: new Group(),
    alertAttachment: null,
    audience: {},
    pending: false,
    loading: true,
    loadingAudience: true,
  },
  mutations: {
    resetGroup (state) {
      state.group = new Group()
    },
    setGroup (state, payload) {
      state.group = new Group(Group.parseRaw(payload))
      state.alertAttachment = state.group.conversions_alert_animation
      state.group.setBootstrapped()
    },
    setAudience (state, payload) {
      state.audience = payload
    },
    togglePending (state, payload) {
      state.pending = payload
    },
    toggleLoading (state, payload) {
      state.loading = payload
    },
    toggleLoadingAudience (state, payload) {
      state.loadingAudience = payload
    },
    setAlertAttachment (state, payload) {
      state.alertAttachment = payload
      state.group.conversions_alert_animation = payload.key
    },
    deleteAlertAttachment (state) {
      state.alertAttachment = null
      state.group.conversions_alert_animation = null
    },
  },
  actions: {
    async fetch ({ commit }, slug) {
      try {
        commit('toggleLoading', true)
        const res = await Api.get('partner/campaigns/ad-set/info', { slug })
        commit('setGroup', res.data)
        return res.data
      }
      finally {
        commit('toggleLoading', false)
      }
    },

    async create ({ commit, state }) {
      const { type } = parseSlug(state.group.campaign)
      const url = `partner/campaigns/${type}/ad-set/create`

      try {
        commit('togglePending', true)
        const res = await Api.post(url, {
          ...omit(state.group.model, isNil),
          slug: state.group.model.campaign,
          streamers: state.group.model.streamers.map(item => item.id),
        })
        return res
      }
      finally {
        commit('togglePending', false)
      }
    },

    async update ({ commit, state }) {
      const { type } = parseSlug(state.group.campaign)
      const url = `partner/campaigns/${type}/ad-set/update`
      const params = {
        ...omit(state.group.model, isNil),
        streamers: state.group.model.streamers.map(item => item.id),
      }

      try {
        commit('togglePending', true)
        const res = await Api.post(url, params)
        ElMessage.success('Group was updated')
        return res
      }
      finally {
        commit('togglePending', false)
      }
    },

    async fetchAudience ({ state, commit }) {
      const { type } = parseSlug(state.group.campaign)
      const data = omit({
        platform: state.group.platform,
        ...state.group.targeting,
        streamers: state.group.targeting.streamers.map(item => item.id),
      }, isNil)

      try {
        commit('toggleLoadingAudience', true)
        const res = await Api.post(`partner/campaigns/${type}/ad-set/audience`, data)
        commit('setAudience', res.data)
        return res.data
      }
      finally {
        commit('toggleLoadingAudience', false)
      }
    },

    reset ({ commit }) {
      commit('resetGroup')
      commit('toggleLoading', true)
    },

    setAlertAttachment ({ commit }, file) {
      commit('setAlertAttachment', file)
    },

    deleteAlertAttachment ({ commit }) {
      commit('deleteAlertAttachment')
    },
  },

  getters: {
    group: ({ group }) => group,
    pending: ({ pending }) => pending,
    loading: ({ loading }) => loading,
    audience: ({ audience }) => audience,
    loadingAudience: ({ loadingAudience }) => loadingAudience,
    alertAttachment: ({ alertAttachment }) => alertAttachment,
  },
}
